<template>
  <b-container fluid>
    <b-row align-v="center" align-h="center" style="height: 75vh">
      <b-col sm="6" md="6" align-v="center">
        <b-card align="center" align-v="center" id="at" style="background-color: rgba(54, 138, 155, 0.8);margin-top: 50px;border-radius: 20px;box-shadow: 5px 5px 10px #5abcae;">
          <div>
            <b-row>
              <b-col cols="2"> </b-col>
              <b-col cols="8">
                <h4 style="color: white;">Woncology v{{d_version}}</h4>
              </b-col>
              <b-col cols="2">
              </b-col>
            </b-row>
          </div>
          <b-card style="margin-top: 0px;background-color: rgba(255, 255, 255, 0.7);border-radius: 15px;">
            <h5 style="color: black;">Çıkış Ekranı</h5> Logout gerçekleştiriliyor.
          </b-card>
          <span style="color:white; text-align: center; display: inline-block; margin-bottom: 10px; margin-top: 10px;">A New Era is Beginning by <a href="https://www.wisdomera.io" rel="follow" target="_blank" style="color:white; text-align: center; display: inline-block; margin-bottom: 10px; margin-top: 10px;">WisdomEra</a></span>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import store from '@/store';
import auth from '@/auth';
import { version } from '@/config';

export default {
  data () {
    return {
      d_version: version
    }
  },
  mounted: function () {
    if (localStorage.getItem('user')) {
      localStorage.removeItem('user');
    }
    if (localStorage.getItem('access_token')) {
      localStorage.removeItem('access_token');
    }
    if (localStorage.getItem('profile_picture')) {
      localStorage.removeItem('profile_picture');
    }
  },
  beforeCreate () {
    /*
      No need to setTimeout in your application. Use only below code.
      auth.logout(this, 'welcome');
    */
    setTimeout(function () {
      auth.logout(this, 'welcome');
      location.reload();
    }.bind(this), 3000)
  }
};

</script>

